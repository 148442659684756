export const lightTheme = {
    colors: {
       section: "#F5FFFB",
       background: 'linear-gradient(180deg, #ACDABE -104.42%, #CCCCCC 57.72%, rgba(81, 106, 91, 0) 219.87%)',
       heavy: "#9E9E9E",
       medium: "#EBEBEB",  
       light: "#F1F1F1",
       lighter: "#F6F6F6",
       line: "#ECECEC",
       lineAlt: "#aac5ac",
       text_title: "#007463",
       nav: "#007463",
       text_primary: "#000000",
       text_secondary: "#000000",
       main: "#fffdfd",
       search: "#f9f9f9",
       disabled: "#898989",
       step: "#93ffa7",
       text: "#0c0c0c",
       dark: "#00306c",
       landingTitle: '#d9d9d9',
       landingSubtitle: '#d7fff2',
       landingFull: '#052F19',
       landingBox: 'linear-gradient(177.62deg, rgba(250, 250, 250, 0) -287.53%, #FAFAFA -287.47%, #052F19 -7.6%, #FFFFFF 423.08%)',
       subContent: '#f9fff8',
       subContentTitle: '#e5fce7',
       lightGreen: '#f9fdf9',
 
       // Cards-Alternative colors
       blue: "#FFA3A3",
       yellow: "#495823",
       green: "#D1DECE",
       black: "#D2D2D2",
       purple: "#EDD8ED",
       red: "#FFE9E9",
       blackwhite: "#FFFFFF"

         // Blockchain specific 
    },
    tool:{
      develop: '#007463',
      code: 'black',
      nft: 'black',
      nodeFill: 'black',
      nodeStroke: 'black',
      unicornFill: 'white',
      unicornStroke: 'black',
      github: 'black',
      dataflow: 'black',
      logo: '#ff6363',
      basic: '#296B00',
    },
    chart: {
      title: "black",
      torso: "black",
      radius: "#007463",
      var1_stroke: "#001c5a",
      var1_fill: "#8884d8",
      var2_stroke: "hwb(119deg 18% 51%)",
      var2_fill: "#82ca9d",
      var3_stroke: "#72221c",
      var3_fill: "red",
      varGray_fill: '#E6E6E6',
      varGray_stroke: "#A1A1A1",
      varOrange_fill: "#EBE3CD",
      varOrange_stroke: "#C2A024",
      varYellow_fill: "#E7EBCD",
      varYellow_stroke: "#A3C224",
      varBlue_fill: "#CDEBE4",
      varBlue_stroke: "#24C2B9",
      varPurple_fill: "#E7CDEB",
      varPurple_stroke: "#C224B3",
      varRed_fill: "#9F3636",
      varRed_stroke: "#DBA0A0",
    }
 }

 export const darkTheme = {
    colors: {
      section: "#FFA3A3",
      background: "linear-gradient(179.87deg, #600000 -104.08%, #000000 156.85%, #000000 417.78%)",
      heavy: "#1F0202",
      medium: "#280000",  
      light: "#601919",
      lighter: "#601919",
      line: "#9F9F9F",
      lineAlt: "#A6E4E8",
      text_title: "#ffcfcf",
      nav: "#000000",
      text_primary: "#A6E4E8",
      text_secondary: "#FFFFFF",
      main: "#010103",
      search: "#000000",
      disabled: "#898989",
      // Cards-Alternative colors
      blue: "#0C002E",
      green: "#012413",
      black: "#000000",
      purple: "#5A0057",
      yellow: "#fffcd9",
      step: "#9d0000",
      text: "#f3f3f3",
      dark: "#c7dcf5",
      landingTitle: '#d9d9d9',
      landingSubtitle: '#cbeaff',
      landingFull: '#A40000',
      landingBox: 'linear-gradient(0deg, #FF0000 -307.13%, #FF0000 -307.09%, #A40000 -40.9%, #0C0000 112.66%)',
      subContent: '#170000',
      subContentTitle: '#000000',
      lightGreen: '#150000',
      red: "#280000",
      blackwhite: "#000000"

      // Blockchain specific 

     },
     tool:{
      develop: 'white',
      code: '#A6E4E8',
      nft: '#FAFF00',
      nodeFill: 'white',
      nodeStroke: 'white',
      unicornFill: '#1F0202',
      unicornStroke: '#A6E4E8',
      github: '#ff6363',
      dataflow: 'white',
      logo: '#ff6363',
      basic: '#CBCF00',
    },
     chart: {
      title: "#A6E4E8",
      torso: "#ffcfcf",
      radius: "#A6E4E8",
      var1_stroke: "rgb(129 149 223)",
      var1_fill: "#8884d8",
      var2_stroke: "rgb(188 241 187)",
      var2_fill: "#82ca9d",
      var3_stroke: "#ff6363",
      var3_fill: "red",
      varGray_fill: '#E6E6E6',
      varGray_stroke: "#A1A1A1",
      varOrange_fill: "#EBE3CD",
      varOrange_stroke: "#C2A024",
      varYellow_fill: "#E7EBCD",
      varYellow_stroke: "#A3C224",
      varBlue_fill: "#CDEBE4",
      varBlue_stroke: "#24C2B9",
      varPurple_fill: "#E7CDEB",
      varPurple_stroke: "#C224B3",
      varRed_fill: "#9F3636",
      varRed_stroke: "#DBA0A0",

    }
    }
 



